import axios from '../utils/http.js'

// 送货单签章列表
export function deliveryNotices(q) {
    return axios.get('/sign/client/deliveryNotices', { params: q })
}

// 送货单详情
export function deliveryNoticesId(params) {
    return axios.get(`/sign/client/deliveryNotices/${params}`);
}

// 拒签
export function refuse(id, params) {
    return axios.post(`/sign/client/deliveryNotices/${id}/refuse`, params )
}

// 同意签章
export function sign(id) {
    return axios.post(`/sign/client/deliveryNotices/${id}/sign`)
}

// 签收人信息
export function profile() {
    return axios.get('/sign/user/profile')
}

// 下载合同
export function download(id) {
    return axios.get(`/sign/client/deliveryNotices/${id}/download`)
}

import { deliveryNotices } from "@/api/home";

export default {
  async updateCart(ctx) {
    const { data } = await deliveryNotices()
    ctx.commit('addCart', {
      count: data.length || 0
    })
  }
}

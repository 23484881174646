import { createApp } from 'vue';
import { NavBar, skeleton, Field, cell, Lazyload, Button, Form, CountDown, Icon, List, PullRefresh, Tab, Tabs} from 'vant';
import App from './App.vue';
import { router } from './router';
import store from './store'
import "@/assets/font/style.css";

const app = createApp(App);
app.use(NavBar)
.use(Field)
.use(Button)
.use(Form)
.use(CountDown)
.use(Icon)
.use(List)
.use(PullRefresh)
.use(Tab)
.use(cell)
.use(skeleton)
.use(Tabs)
.use(Lazyload)

app.use(router)
app.use(store)
app.mount('#app')

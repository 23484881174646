<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
body {
  font-size: 16px;
  background-color: #f1f1f1;
  -webkit-font-smoothing: antialiased;
  color: #333333;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grey {
  color: #666;
}
.red {
  color: red;
}
.c-der {
  color: #ff7300;
}
.f12 {
  font-size: 12px;
}
</style>

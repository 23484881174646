export default {
  addCart (state, payload) {
    state.cartCount = payload.count
  },

  upNum (state, o) {
    state.activeNum = o
  },

  mutSpage (state, n) {
    state.spage = n
  },

  mutList (state, n) {
    state.dataList = n
  }
}

import axios from 'axios'
import { Toast } from 'vant'
import { useRouter } from "vue-router";
// http://signzzs.lessoald.cn   测试
// http://signprd.lessoald.cn   准正式
// https://sign.yos168.com     正式

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_URL
axios.defaults.withCredentials = true
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
const userinfoObj = localStorage.getItem('userinfo')
axios.defaults.headers['Authorization'] = userinfoObj ? JSON.parse(userinfoObj).token : ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail('服务端异常')
    return Promise.reject(res)
  }
  if (res.data.recode != 200) {
    if (res.data.msg) Toast(res.data.msg)
    if (res.data.recode == 201) {
      localStorage.clear()
      const router = useRouter();
      router.push({ path: '/login' })
    }
    if (res.data.recode == 5000 || res.data.recode == 5001 || res.data.recode == 5002 || res.data.recode == 5003 || res.data.recode == 5004) {
      setTimeout(function() {
        localStorage.clear()
        location.href = '/login';
      }, 1500)
    }
    return res.data
  }

  return res.data
},
  error => {
    if (error.response) {
      Toast("出错了:" + error.response.status)
    }
  })

export default axios